import { Business, Phone, Store } from '@interfaces';

import { BusinessType } from './business.interface';

export interface SimpleRole {
  role: string;
  store: string;
  user: string;
}

export class User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  phone: Phone;
  dni: string;
  cuit: string;
  businessName: string;
  isActive: boolean;
  token: string;
  business: Business[];
  stores: Store[];
  storeIds: string[];
  assignedRoles?: SimpleRole[];

  get businessType(): BusinessType {
    return this.business[0].type;
  }

  get mainStore(): Store {
    return this.stores?.[0];
  }

  get fullName(): string {
    if (this.firstName && this.lastName) {
      const nameCapitalized =
        this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1);
      const lastNameCapitalized =
        this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1);

      return `${nameCapitalized} ${lastNameCapitalized}`;
    }

    return 'Usuario anónimo';
  }

  get phoneNumber(): string {
    if (this.phone.areaCode && this.phone.number) {
      return `${this.phone.areaCode.replace('+', '')}${this.phone.number}`;
    }
    return '';
  }

  get whatsAppWelcomeText(): string {
    return `Hola ${this.firstName.trim()}! Cómo estás? Soy Milagros de Pedisy, software gastronómico para restaurantes.%0aTe contacto ya que completaste el formulario de registro en nuestra web.%0a%0aMe gustaría saber si tenés alguna duda con respecto al sistema. Me comentarías que estás buscando cubrir en tu local gastronómico?`;
  }

  get accessToken(): string {
    return this.token;
  }

  constructor(userData: Partial<User>) {
    this.id = userData?.id;
    this.username = userData?.username;
    this.firstName = userData?.firstName;
    this.lastName = userData?.lastName;
    this.phone = userData?.phone;
    this.dni = userData?.dni;
    this.cuit = userData?.cuit;
    this.businessName = userData?.businessName;
    this.isActive = userData?.isActive || false;
    this.token = userData?.token;
    this.business = userData?.business || [];
    this.stores = userData?.stores || [];
    this.storeIds = userData?.storeIds || [];
  }
}
