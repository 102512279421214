// `.env.ts` is generated by the `npm run env` command
import env from './.env';
import firebase from './firebase';

export const environment = {
  production: true,
  version: env.npm_package_version,
  appUrl: 'https://web.pedisy.com',
  serverUrl: 'https://server.pedisy.com',
  envName: 'PROD',
  API_URL: 'assets/api',
  firebase,
};
