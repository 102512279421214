import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { IRequestOptions } from '@shared/interfaces/iRequestOptions.interface';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private api = environment.serverUrl + '/';

  public constructor(public http: HttpClient) {}

  /**
   * GET request
   * @param endPoint it doesn't need / in front of the end point
   * @param options options of the request like headers, body, etc.
   */
  public get<T>(endPoint: string, options?: any): Observable<any> {
    return this.http.get<T>(this.api + endPoint, options);
  }

  /**
   * FETCH request
   * @param endPoint it doesn't need / in front of the end point
   * @param options options of the request like headers, body, etc.
   */
  public async fetch<T>(
    endPoint: string,
    options?: IRequestOptions
  ): Promise<T> {
    return await firstValueFrom(
      this.http.get<T>(`${this.api}${endPoint}`, options)
    );
  }

  /**
   * POST request
   * @param endPoint end point of the api
   * @param params body of the request.
   * @param options options of the request like headers, body, etc.
   */
  public post<T>(
    endPoint: string,
    params: object,
    options?: IRequestOptions
  ): Observable<T> {
    return this.http.post<T>(this.api + endPoint, params, options);
  }

  /**
   * PUT request
   * @param endPoint end point of the api
   * @param params body of the request.
   * @param options options of the request like headers, body, etc.
   */
  public put<T>(
    endPoint: string,
    params: object,
    options?: IRequestOptions
  ): Observable<T> {
    return this.http.put<T>(this.api + endPoint, params, options);
  }

  /**
   * PATCH request
   * @param endPoint end point of the api
   * @param params body of the request.
   * @param options options of the request like headers, body, etc.
   */
  public patch<T>(
    endPoint: string,
    params: object,
    options?: IRequestOptions
  ): Observable<T> {
    return this.http.patch<T>(this.api + endPoint, params, options);
  }

  /**
   * DELETE request
   * @param endPoint end point of the api
   * @param options options of the request like headers, body, etc.
   */
  public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(this.api + endPoint, options);
  }

  /**
   * POST request
   * @param params body of the request.
   * @param options options of the request like headers, body, etc.
   */
  public postFile<T>(
    params: FormData,
    options?: IRequestOptions
  ): Observable<T> {
    return this.http.post<T>(`${this.api}files/upload`, params, options);
  }

  /**
   * POST request
   * @param endPoint end point of the api
   * @param filename body of the request.
   */
  public download<T>(endPoint: string, body: object): Observable<T> {
    return this.http.post<T>(this.api + endPoint, body, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
    });
  }
}
