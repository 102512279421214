import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SwalService {
  constructor() { }

  success(
    title: string = 'Operación exitosa!',
    text: string = '',
    timer: number | undefined = 1500,
    confirmButton = false,
    confirmButtonText = 'Aceptar'
  ): boolean {
    Swal.fire({
      title,
      text,
      icon: 'success',
      timer,
      showConfirmButton: confirmButton,
      confirmButtonText,
      confirmButtonColor: '#000',
    });
    return true;
  }

  warning(
    title: string = 'Lo sentimos!',
    text: string = 'Algo parece andar mal.',
    timer: any = 1500,
    confirmButton = false,
    confirmButtonText = 'Aceptar',
    cancelButton = false,
    cancelButtonText = 'Cancelar'
  ): boolean {
    Swal.fire({
      title,
      text,
      icon: 'warning',
      showConfirmButton: confirmButton,
      confirmButtonText,
      confirmButtonColor: '#000',
      showCancelButton: cancelButton,
      cancelButtonText,
      timer,
    });
    return true;
  }

  error(
    title: string = 'Error!',
    text: string = 'Ha ocurrido un error inesperado.',
    timer: number = 2000
  ): boolean {
    Swal.fire({
      title,
      text,
      icon: 'error',
      showConfirmButton: false,
      timer,
    });
    return true;
  }

  delete(
    title: string = '¿Estás seguro que quieres eliminar este registro?',
    text: string = 'No podrás revertir los cambios.'
  ): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title,
      text,
      showDenyButton: true,
      confirmButtonColor: '#000',
      confirmButtonText: 'Sí, eliminar',
      denyButtonText: 'Cancelar',
    });
  }
}
